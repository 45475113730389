<template>
  <div class="container-fluid">
    <div class="row m-3">
      <div class="col-11">
        <div class="row">
          <div class="col-2">
            Эхлэх огноо
            <b-form-datepicker v-model="filter.startDate"></b-form-datepicker>
          </div>
          <div class="col-2">
            Дуусах огноо
            <b-form-datepicker v-model="filter.endDate"></b-form-datepicker>
          </div>
          <div class="col-2">
            <div class="btn-group">
              <a @click="init()" class="btn btn-primary">Хайх</a>  
              <a @click="initReset()" class="btn btn-default">Бүгд</a>  
              
              <b-button v-if="loading" variant="warning" disabled>
                  <b-spinner small type="grow"></b-spinner>
                  Loading...
              </b-button>
              <a v-else @click="excel()" class="btn btn-success">Excel</a>  
            </div>
          </div>
          
        </div>
      </div>
      <div class="col-1">
        <a @click="logOut" class="btn btn-danger">Гарах?</a>
      </div>
    </div>
    
    <b-table id="sms-table" striped hover :items="items"
    ></b-table>
    
    <div class="text-center">
      <b-pagination
        @change="pageChanged"
        v-model="currentPage"
        :total-rows="itemsLength"
        :per-page="perPage"
      ></b-pagination> 
      <div>Нийт: {{itemsLength}}</div>
    </div>
  </div>
</template>

<script>
const moment = require('moment')

export default {
  data(){
    return{
      items: [],
      itemsLength: 0,
      perPage: 10,
      currentPage: 1,
      filter:{
        startDate: null,
        endDate: null
      },
      loading: false
    }
  },
  mounted(){
    if(this.$cookie.get('token')!=undefined && this.$cookie.get('token')!=null && this.$cookie.get('token')!='')
      this.init();
    else this.logOut();
  },
  methods:{
    initReset(){
      this.filter.startDate= null;
      this.filter.endDate= null;
      this.currentPage= 1;
      this.init();
    },
    init(){
      // this.items=[];
      // this.itemsLength=0;

      this.axios({
        url: 'https://smsapi.gosmart.mn/core/sms/list',
        method: 'POST',
        data:{
          startDate: this.filter.startDate,
          endDate: this.filter.endDate,
          page: this.currentPage-1,
          size: this.perPage
        },
        headers: { 'Authorization': `Bearer ${ this.$cookie.get('token') }` }
      }).then((res) => {
        if(res.data.code==200){
          this.items= res.data.data.list.map((e)=>{
            return {
              Огноо: moment(e.date).format('YYYY.MM.DD HH:mm:SS'),
              Дугаар: e.phone,
              Мессеж: e.msg,
              Оператор: e.operator.name,
              Төлөв: e.flag
            }
          });
          this.itemsLength= res.data.data.count;
          ///console.log(res.data)          
        }else if(res.data.code==401){
          this.logOut();
        }else{
          console.log('else', res.data)
        }
      })
    },
    pageChanged(e) {
      this.currentPage= e;
      this.init();
    },
    logOut(){
      this.$cookie.set('token', '');
      this.$router.push({name: 'Home'});
    },
    excel(){
      this.loading= true;
      this.axios({
        url: 'https://smsapi.gosmart.mn/core/sms/excel',
        method: 'GET',
        responseType: 'blob',
        params:{
          startDate: this.filter.startDate,
          endDate: this.filter.endDate,
        },
        headers: { 
          'Authorization': `Bearer ${ this.$cookie.get('token') }`,
          "Content-Type": "application/octet-stream"
        }
      }).then((res) => {
        this.loading= false;
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.xls'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
    }
  }
}
</script>

<style>

</style>
